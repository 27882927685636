import React from 'react';
import '../main.css';
import { AuthorPile, InfoPage } from './HelperComponents';
import Details from './Details';
import * as dataObjects from './dataObjects.js';

export function PolicyPrototypingLLMs() {

  return (
    <InfoPage helmetExt="Policy Prototyping for LLMs">

      <h2>{dataObjects.PolicyPrototypingObj.title}</h2>
      <AuthorPile authors={dataObjects.PolicyPrototypingObj.authors}/>
      <p className="venue-page">{dataObjects.PolicyPrototypingObj.venue}</p>

      <h3>Abstract</h3>
      <p>Emerging efforts in AI alignment seek to broaden participation in shaping model behavior by eliciting and integrating collective input into a policy for model finetuning. While pluralistic, these processes are often linear and do not allow participating stakeholders to confirm whether potential outcomes of their contributions are indeed consistent with their intentions. Design prototyping has long advocated for rapid iteration using tight feedback loops of ideation, experimentation, and evaluation to mitigate these issues. We thus propose policy prototyping for LLMs, a new process that draws inspiration from prototyping practices to enable stakeholders to collaboratively and interactively draft LLM policies. Through learnings from a real-world LLM policymaking initiative at an industrial AI lab, we motivate our approach and characterize policy prototyping with four guiding principles. Because policy prototyping emphasizes a contrasting set of priorities compared to previous approaches, we envision our approach to be a valuable addition to the methodological repertoire for pluralistic alignment.</p>

      <h3>Details</h3>
      <Details {...dataObjects.PolicyPrototypingObj}/>

    </InfoPage>
  );
}

export function AIamNotALawyer() {

  return (
    <InfoPage helmetExt="Engaging Legal Experts towards Responsible LLM Policies for Legal Advice">

      <h2>{dataObjects.AIamNotALawyerObj.title}</h2>
      <AuthorPile authors={dataObjects.AIamNotALawyerObj.authors}/>
      <p className="venue-page">{dataObjects.AIamNotALawyerObj.venue}</p>

      <h3>Abstract</h3>
      <p>Large language models (LLMs) are increasingly capable of providing users with advice in a wide range of professional domains, including legal advice. However, relying on LLMs for legal queries raises concerns due to the significant expertise required and the potential real-world consequences of the advice. To explore when and why LLMs should or should not provide advice to users, we conducted workshops with 20 legal experts using methods inspired by case-based reasoning. The provided realistic queries (“cases”) allowed experts to examine granular, situation-specific concerns and overarching technical and legal constraints, producing a concrete set of contextual considerations for LLM developers. By synthesizing the factors that impacted LLM response appropriateness, we present a 4-dimension framework: (1) User attributes and behaviors, (2) Nature of queries, (3) AI capabilities, and (4) Social impacts. We share experts’ recommendations for LLM response strategies, which center around helping users identify ‘right questions to ask’ and relevant information rather than providing definitive legal judgments. Our findings reveal novel legal considerations, such as unauthorized practice of law, confidentiality, and liability for inaccurate advice, that have been overlooked in the literature. The case-based deliberation method enabled us to elicit fine-grained, practice-informed insights that surpass those from de-contextualized surveys or speculative principles. These findings underscore the applicability of our method for translating domain-specific professional knowledge and practices into policies that can guide LLM behavior in a more responsible direction.</p>

      <h3>Details</h3>
      <Details {...dataObjects.AIamNotALawyerObj}/>

    </InfoPage>
  );
}

export function TeachableFeedExperiences() {

  return (
    <InfoPage helmetExt="Mapping the Design Space of Teachable Social Media Feed Experiences">

      <h2>{dataObjects.TeachableFeedExperiencesObj.title}</h2>
      <AuthorPile authors={dataObjects.TeachableFeedExperiencesObj.authors}/>
      <p><i>* equal contribution</i></p>
      <p className="venue-page">{dataObjects.TeachableFeedExperiencesObj.venue}</p>

      <h3>Abstract</h3>
      <p>Social media feeds are deeply personal spaces that reflect individual values and preferences. However, top-down, platform-wide content algorithms can reduce users' sense of agency and fail to account for nuanced experiences and values. Drawing on the paradigm of interactive machine teaching (IMT), an interaction framework for non-expert algorithmic adaptation, we map out a design space for teachable social media feed experiences to empower agential, personalized feed curation. To do so, we conducted a think-aloud study (N=24) featuring four social media platforms—Instagram, Mastodon, TikTok, and Twitter—to understand key signals users leveraged to determine the value of a post in their feed. We synthesized users' signals into taxonomies that, when combined with user interviews, inform five design principles that extend IMT into the social media setting. We finally embodied our principles into three feed designs that we present as sensitizing concepts for teachable feed experiences moving forward.</p>

      <h3>Details</h3>
      <Details {...dataObjects.TeachableFeedExperiencesObj}/>

    </InfoPage>
  );
}

export function Canvil() {

  return (
    <InfoPage helmetExt="Canvil: Designerly Adaptation for LLM-Powered User Experiences">

      <h2>{dataObjects.CanvilObj.title}</h2>
      <AuthorPile authors={dataObjects.CanvilObj.authors}/>
      <p className="venue-page">{dataObjects.CanvilObj.venue}</p>

      <h3>Abstract</h3>
      <p>Advancements in large language models (LLMs) are sparking a proliferation of LLM-powered user experiences (UX). In product teams, designers often craft UX to meet user needs, but it is unclear how they engage with LLMs as a novel design material. Through a formative study with 12 designers, we find that designers seek a translational mechanism that enables design requirements to shape and be shaped by LLM behavior, motivating a need for designerly adaptation to facilitate this translation. We then built Canvil, a Figma widget that operationalizes designerly adaptation. We used Canvil as a technology probe in a group-based design study (6 groups, N=17), finding that designers constructively iterated on both adaptation approaches and interface designs to enhance end-user interaction with LLMs. Furthermore, designers identified promising collaborative workflows for designerly adaptation. Our work opens new avenues for processes and tools that foreground designers' user-centered expertise in LLM-powered applications.</p>

      <h3>Details</h3>
      <Details {...dataObjects.CanvilObj}/>

    </InfoPage>
  );
}
