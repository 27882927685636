import React from 'react';
import '../main.css';
import { AuthorPile, Figure, InfoPage } from './HelperComponents';
import Details from './Details';
import * as dataObjects from './dataObjects.js';

export function ShapedWordClouds() {

  return (
    <InfoPage helmetExt="Towards Semantically Aware Word Cloud Shape Generation">

      <h2>{dataObjects.ShapedWordCloudsObj.title}</h2>
      <AuthorPile authors={dataObjects.ShapedWordCloudsObj.authors}/>
      <p><i>* equal contribution</i></p>
      <p className="venue-page">{dataObjects.ShapedWordCloudsObj.venue}</p>

      <Figure path="./img/wordclouds-teaser.png" alt="6 word clouds in the shape of their respective topics, coloured mostly in shades of purple, green, and yellow."/>

      <h3>Abstract</h3>
      <p>Word clouds are a data visualization technique that showcases a subset of words from a body of text in a cluster form, where a word’s font size encodes some measure of its relative importance—typically frequency—in the text. This technique is primarily used to help viewers glean the most pertinent information from long text documents and to compare and contrast different pieces of text. Despite their popularity, previous research has shown that word cloud designs are often not optimally suited for analytical tasks such as summarization or topic understanding. We propose a solution for generating more effective visualization technique that shapes the word cloud to reflect the key topic(s) of the text. Our method automates the processes of manual image selection and masking required from current word cloud tools to generate shaped word clouds, better allowing for quick summarization. We showcase two approaches using classical and state-of-the-art methods. Upon successfully generating semantically shaped word clouds using both methods, we performed preliminary evaluations with 5 participants. We found that although most participants preferred shaped word clouds over regular ones, the shape can be distracting and detrimental to information extraction if it is not directly relevant to the text or contains graphical imperfections. Our work has implications on future semantically-aware word cloud generation tools as well as efforts to balance visual appeal of word clouds with their effectiveness in textual comprehension.</p>

      <h3>Details</h3>
      <Details {...dataObjects.ShapedWordCloudsObj}/>

    </InfoPage>
  );
}

export function WhatsappMisinfo() {

  return (
    <InfoPage helmetExt="Misinformation on WhatsApp">

      <h2>{dataObjects.WhatsappMisinfoObj.title}</h2>
      <AuthorPile authors={dataObjects.WhatsappMisinfoObj.authors}/>
      <p className="venue-page">{dataObjects.WhatsappMisinfoObj.venue}</p>

      <h3>Abstract</h3>
      <p>Misinformation can spread easily in end-to-end encrypted messaging platforms such as WhatsApp where many groups of people are communicating with each other. Approaches to combat misinformation may also differ amongst younger and older adults. In this paper, we investigate how young adults encountered and dealt with misinformation on WhatsApp in private group chats during the first year of the COVID-19 pandemic. To do so, we conducted a qualitative interview study with 16 WhatsApp users who were university students based in the United States. We uncovered three main findings. First, all participants encountered misinformation multiple times a week in group chats, often attributing the source of misinformation to be well-intentioned family members. Second, although participants were able to identify misinformation and fact-check using diverse methods, they often remained passive to avoid negatively impacting family relations. Third, participants agreed that WhatsApp bears a responsibility to curb misinformation on the platform but expressed concerns about its ability to do so given the platform's steadfast commitment to content privacy. Our findings suggest that conventional content moderation techniques used by open platforms such as Twitter and Facebook are unfit to tackle misinformation on WhatsApp. We offer alternative design suggestions that take into consideration the social nuances and privacy commitments of end-to-end encrypted group chats. Our paper also contributes to discussions between platform designers, researchers, and end users on misinformation in privacy-preserving environments more broadly.</p>

      <h3>Details</h3>
      <Details {...dataObjects.WhatsappMisinfoObj}/>

    </InfoPage>
  );
}


export function Handoffs() {

  return (

    <InfoPage helmetExt="From Handoffs to Co-Creation">

      <h2>From Handoffs to Co-Creation: Deepening Collaboration between Designers, Developers, and Data Science Workers in UX Design </h2>
      <AuthorPile authors={dataObjects.HandoffsObj.authors}/>
      <p className="venue-page">{dataObjects.HandoffsObj.venue}</p>

      {/* <Figure path="./img/adoculos-cover.png" alt="A screenshot of the AdOculos interface." /> */}

      <h3>Abstract</h3>
      <p>UX design tools have made significant advancements in supporting real-time collaboration within design files that drastically shift the way teams discuss and iterate on designs. However, designs often still have little connection to the underlying technologies that power user interfaces when implemented, which limits how deeply designers can collaborate with technical non-designers.
      We propose new ways design tools can move from one-time handoffs towards supporting deeper <i>co-creation</i> between designers and technical stakeholders, specifically developers and data science workers, to create interactive and expressive interface designs. We do so through two main focus areas that connect code and data with design—bridging designer-developer divergence through component-based synchronization, and empowering designerly interaction with data to help prototype probabilistic interfaces. We conclude with a discussion on the implications of these two focus areas on future design tools.</p>

      <h3>Details</h3>
      <Details {...dataObjects.HandoffsObj}/>

    </InfoPage>
  );
}

export function CFExp() {

  return (

    <InfoPage helmetExt="Counterfactual Explanations in Everyday Recommendations">

      <h2>Understanding Lay Users' Needs in Counterfactual Explanations for Everyday Recommendations</h2>
      <AuthorPile authors={dataObjects.CFExpObj.authors}/>
      <p className="venue-page">{dataObjects.CFExpObj.venue}</p>

      {/* <Figure path="./img/adoculos-cover.png" alt="A screenshot of the AdOculos interface." /> */}


      <h3>Abstract</h3>
      <p>Intelligent everyday applications typically rely on automated Recommender Systems (RS) to generate recommendations that help users make decisions among a large number of options. Due to the increasing complexity of RS and the lack of transparency in its algorithmic decision-making, researchers have recognized the need to support users with more explanations. While traditional explainability methods fall short in disclosing the internal intricacy of recommender systems, counterfactual explanations provide many desirable explainable features by offering human-like explanations that contrast an existing recommendation with alternatives. However, there is a lack of empirical research in understanding lay users’ needs of counterfactual explanations in their usage of everyday intelligent applications. In this paper, we investigate whether and where should we provide counterfactual explanations in everyday recommender systems through a question-driven approach. We first conducted an interview study to understand how existing explanations might be insufficient to support lay users and elicit the triggers that prompt them to ask <i>why not</i> questions and seek additional explanations. The findings reveal that the utility of decision is a primary factor that may affect whether users want to consume counterfactual explanations. We then conducted an online scenario-based survey to quantify the correlation between utility and explanation needs and found significant correlations between the measured variables. </p>

      <h3>Details</h3>
      <Details {...dataObjects.CFExpObj}/>

    </InfoPage>
  );
}

