import React from 'react';
import '../main.css';
import { AuthorPile, InfoPage } from './HelperComponents';
import Details from './Details';
import * as dataObjects from './dataObjects.js';

export function CaseRepositories() {

  return (
    <InfoPage helmetExt="Case Repositories: Towards Case-Based Reasoning for AI Alignment">

      <h2>{dataObjects.CaseRepositoriesObj.title}</h2>
      <AuthorPile authors={dataObjects.CaseRepositoriesObj.authors}/>
      <p className="venue-page">{dataObjects.CaseRepositoriesObj.venue}</p>

      <h3>Abstract</h3>
      <p>Case studies commonly form the pedagogical backbone in law, ethics, and many other domains that face complex and ambiguous societal questions informed by human values. Similar complexities and ambiguities arise when we consider how AI should be aligned in practice: when faced with vast quantities of diverse (and sometimes conflicting) values from different individuals and communities, with whose values is AI to align, and how should AI do so? We propose a complementary approach to constitutional AI alignment, grounded in ideas from case-based reasoning (CBR), that focuses on the construction of policies through judgments on a set of cases. We present a process to assemble such a case repository by: 1) gathering a set of ``seed'' cases—questions one may ask an AI system—in a particular domain, 2) eliciting domain-specific key dimensions for cases through workshops with domain experts, 3) using LLMs to generate variations of cases not seen in the wild, and 4) engaging with the public to judge and improve cases. We then discuss how such a case repository could assist in AI alignment, both through directly acting as precedents to ground acceptable behaviors, and as a medium for individuals and communities to engage in moral reasoning around AI.</p>

      <h3>Details</h3>
      <Details {...dataObjects.CaseRepositoriesObj}/>

    </InfoPage>
  );
}

export function C2PAProvenance() {

  return (
    <InfoPage helmetExt="Examining the Impact of Provenance-Enabled Media on Trust and Accuracy Perceptions">

      <h2>{dataObjects.C2PAProvenanceObj.title}</h2>
      <AuthorPile authors={dataObjects.C2PAProvenanceObj.authors}/>
      <p className="venue-page">{dataObjects.C2PAProvenanceObj.venue}</p>

      <h3>Abstract</h3>
      <p>In recent years, industry leaders and researchers have proposed to use technical provenance standards to address visual misinformation spread through digitally altered media. By adding immutable and secure provenance information such as authorship and edit date to media metadata, social media users could potentially better assess the validity of the media they encounter. However, it is unclear how end users would respond to provenance information, or how to best design provenance indicators to be understandable to laypeople. We conducted an online experiment with 595 participants from the US and UK to investigate how provenance information altered users' accuracy perceptions and trust in visual content shared on social media. We found that provenance information often lowered trust and caused users to doubt deceptive media, particularly when it revealed that the media was composited. We additionally tested conditions where the provenance information itself was shown to be incomplete or invalid, and found that these states have a significant impact on participants' accuracy perceptions and trust in media, leading them, in some cases, to disbelieve honest media. Our findings show that provenance, although enlightening, is still not a concept well-understood by users, who confuse media credibility with the orthogonal (albeit related) concept of provenance credibility. We discuss how design choices may contribute to provenance (mis)understanding, and conclude with implications for usable provenance systems, including clearer interfaces and user education.</p>

      <h3>Details</h3>
      <Details {...dataObjects.C2PAProvenanceObj}/>

    </InfoPage>
  );
}

export function UXAIComm() {

  return (
    <InfoPage helmetExt="How Do UX Practitioners Communicate AI as a Design Material? Artifacts, Conceptions, and Propositions">

      <h2>{dataObjects.UXAICommObj.title}</h2>
      <AuthorPile authors={dataObjects.UXAICommObj.authors}/>
      <p className="venue-page">{dataObjects.UXAICommObj.venue}</p>

      <h3>Abstract</h3>
      <p>UX practitioners (UXPs) face novel challenges when working with and communicating artificial intelligence (AI) as a design material. We explore how UXPs communicate AI concepts when given hands-on experience training and experimenting with AI models. To do so, we conducted a task-based design study with 27 UXPs in which they prototyped and created a design presentation for a AI-enabled interface while having access to a simple AI model training tool. Through analyzing UXPs’ design presentations and post-activity interviews, we found that although UXPs struggled to clearly communicate some AI concepts, tinkering with AI broadened common ground when communicating with technical stakeholders. UXPs also identified key risks and benefits of AI in their designs, and proposed concrete next steps for both UX and AI work. We conclude with a sensitizing concept and recommendations for design and AI tools to enhance multi-stakeholder communication and collaboration when crafting human-centered AI experiences.</p>

      <h3>Details</h3>
      <Details {...dataObjects.UXAICommObj}/>

    </InfoPage>
  );
}

export function UXCollab() {

  return (
    <InfoPage helmetExt="Understanding Collaborative Practices and Tools of Professional UX Practitioners in Software Organizations">

      <h2>{dataObjects.UXCollabObj.title}</h2>
      <AuthorPile authors={dataObjects.UXCollabObj.authors}/>
      <p className="venue-page">{dataObjects.UXCollabObj.venue}</p>

      <h3>Abstract</h3>
      <p>User experience (UX) has undergone a revolution in collaborative practices, due to tools that enable quick feedback and continuous collaboration with a varied team across a design’s lifecycle. However, it is unclear how this shift in collaboration has been received in professional UX practice, and whether new pain points have arisen. To this end, we conducted a survey (N = 114) with UX practitioners at software organizations based in the U.S. to better understand their collaborative practices and tools used throughout the design process.We found that while an increase in collaborative activity enhanced many aspects of UX work, some long-standing challenges—such as handing off designs to developers—still persist. Moreover, we observed new challenges emerging from activities enabled by collaborative tools such as design system management. Based on our findings, we discuss how UX practices can improve collaboration moving forward and provide concrete design implications for collaborative UX tools.</p>

      <h3>Details</h3>
      <Details {...dataObjects.UXCollabObj}/>

    </InfoPage>
  );
}

export function UXIML() {

  return (
    <InfoPage helmetExt="Addressing UX Practitioners’ Challenges in Designing ML Applications: an Interactive Machine Learning Approach">

      <h2>{dataObjects.UXIMLObj.title}</h2>
      <AuthorPile authors={dataObjects.UXIMLObj.authors}/>
      <p className="venue-page">{dataObjects.UXIMLObj.venue}</p>

      {/* <Figure path="./img/wordclouds-teaser.png" alt="6 word clouds in the shape of their respective topics, coloured mostly in shades of purple, green, and yellow."/> */}

      <h3>Abstract</h3>
      <p>UX practitioners face novel challenges when designing user interfaces for machine learning (ML)-enabled applications. Interactive ML paradigms, like AutoML and interactive machine teaching, lower the barrier for non-expert end users to create, understand, and use ML models, but their application to UX practice is largely unstudied. We conducted a task-based design study with 27 UX practitioners where we asked them to propose a proof-of-concept design for a new ML-enabled application. During the task, our participants were given opportunities to create, test, and modify ML models as part of their workflows. Through a qualitative analysis of our post-task interview, we found that direct, interactive experimentation with ML allowed UX practitioners to tie ML capabilities and underlying data to user goals, compose affordances to enhance end-user interactions with ML, and identify ML-related ethical risks and challenges. We discuss our findings in the context of previously established human-AI guidelines. We also identify some limitations of interactive ML in UX processes and propose research-informed machine teaching as a supplement to future design tools alongside interactive ML.</p>

      <h3>Details</h3>
      <Details {...dataObjects.UXIMLObj}/>

    </InfoPage>
  );
}

